import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import './UploadForm.css';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

const API_URL = process.env.REACT_APP_API_URL;

const UploadForm = () => {
    const [selectedBudowa, setSelectedBudowa] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
    const [text, setText] = useState("");
    const [images, setImages] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isCompressing, setIsCompressing] = useState(false);

    const handleBudowaChange = (event) => setSelectedBudowa(event.target.value);

    const handleTextChange = (event) => setText(event.target.value);

    const handleImageChange = async (event) => {
        const selectedFiles = Array.from(event.target.files);
        const compressedImages = [];
        const options = { maxSizeMB: 1, maxWidthOrHeight: 800, useWebWorker: true };

        setIsCompressing(true);
        try {
            for (let file of selectedFiles) {
                const compressedFile = await imageCompression(file, options);
                const uniqueFileName = `${file.name.split('.')[0]}_${Date.now()}.${compressedFile.type.split('/')[1]}`;
                const newFile = new File([compressedFile], uniqueFileName, { type: compressedFile.type });
                compressedImages.push(newFile);
            }
            setImages(compressedImages);
        } catch (error) {
            console.error("Błąd kompresji obrazu:", error);
            setErrorMessage("Błąd kompresji obrazu. Spróbuj ponownie.");
        } finally {
            setIsCompressing(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!selectedBudowa) {
            setErrorMessage("Proszę wybrać budowę.");
            setSuccessMessage("");
            return;
        }

        if (!images.length || !text.trim()) {
            setErrorMessage("Proszę dodać opis i co najmniej jedno zdjęcie.");
            setSuccessMessage("");
            return;
        }

        const formData = new FormData();
        formData.append('budowa', selectedBudowa);
        formData.append('date', format(new Date(selectedDate), 'dd/MM/yyyy', { locale: de }));
        formData.append('text', text);
        images.forEach((image) => formData.append('images', image));

        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${API_URL}/upload`, true);
        const token = localStorage.getItem('token');
        if (token) xhr.setRequestHeader("Authorization", `Bearer ${token}`);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = Math.round((event.loaded / event.total) * 100);
                setUploadProgress(progress);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                setSuccessMessage("Pliki zostały pomyślnie przesłane!");
                setErrorMessage("");
                resetForm();
            } else {
                setErrorMessage("Wystąpił problem z przesłaniem plików.");
                setSuccessMessage("");
            }
        };

        xhr.onerror = () => {
            setErrorMessage("Błąd: Nie udało się przesłać formularza. Spróbuj ponownie.");
            setSuccessMessage("");
        };

        xhr.send(formData);
    };

    const resetForm = () => {
        setSelectedBudowa("");
        setSelectedDate(new Date().toISOString().split("T")[0]);
        setText("");
        setImages([]);
        setUploadProgress(0);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="upload-form">
                <label>
                    Baustelle:
                    <select value={selectedBudowa} onChange={handleBudowaChange} className="custom-select">
                        <option value="">Baustele auswählen</option>
                        <option value="ahq">AHQ</option>
                        <option value="maxfreii">MaxFreii</option>
                        <option value="centerpark">CenterParcsBostalsee</option>
                    </select>
                </label>
                <br />

                <label>
                    Datum:<br />
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        className="date-picker"
                    />
                </label>
                <br />

                <label>
                    Beschreibung:
                    <textarea value={text} onChange={handleTextChange} />
                </label>
                <br />

                <label>
                    Fügen Sie Fotos hinzu:
                    <input type="file" multiple accept="image/*" onChange={handleImageChange} />
                </label>
                <br />

                <button type="submit" disabled={isCompressing || !images.length || !text.trim() || !selectedBudowa}>
                    {isCompressing ? "Kompresowanie..." : "Senden"}
                </button>

                {uploadProgress > 0 && (
                    <div style={{ marginTop: '10px' }}>
                        <progress value={uploadProgress} max="100"></progress>
                        <span> {uploadProgress}%</span>
                    </div>
                )}

                {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </form>
        </div>
    );
};

export default UploadForm;
