import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import './SearchPhotos.css';

const API_URL = process.env.REACT_APP_API_URL;

const SearchPhotos = () => {
    const [budowa, setBudowa] = useState('');
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [query, setQuery] = useState('');
    const [error, setError] = useState('');
    const [activeIndex, setActiveIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
    const [modalPhotos, setModalPhotos] = useState([]);
    const [zipProgress, setZipProgress] = useState({});
    const [downloadProgress, setDownloadProgress] = useState({});
    const [isDownloading, setIsDownloading] = useState({}); // Stan kontrolujący dostępność przycisku ZIP Download

    const fetchDates = async () => {
        try {
            const queryParams = `budowa=${budowa}&year=${selectedDate.split('-')[0] || ''}&month=${selectedDate.split('-')[1] || ''}&day=${selectedDate.split('-')[2] || ''}&q=${query}`;
            const response = await fetch(`${API_URL}/api/list-dates?${queryParams}`);

            if (!response.ok) throw new Error('Nie znaleziono wyników dla wybranych kryteriów.');

            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("Oczekiwano JSON, ale otrzymano HTML");
            }

            let data = await response.json();

            const sortedDates = data
                .map(item => ({
                    ...item,
                    parsedDate: new Date(item.date.split('/').reverse().join('-'))
                }))
                .sort((a, b) => b.parsedDate - a.parsedDate);

            const filteredDates = query 
                ? sortedDates.filter(dateData => dateData.opis.toLowerCase().includes(query.toLowerCase()))
                : sortedDates;

            setDates(filteredDates);
            setError('');
        } catch (err) {
            setError(err.message);
            setDates([]);
        }
    };

    const handleBudowaChange = (e) => {
        setBudowa(e.target.value);
        setSelectedDate('');
        setDates([]);
        setActiveIndex(null);
    };

    const handleSearch = () => {
        if (budowa) {
            fetchDates();
        } else {
            setError('Wybierz budowę, aby rozpocząć wyszukiwanie.');
        }
    };

    const toggleSection = (index, photos) => {
        setActiveIndex(index === activeIndex ? null : index);
        setModalPhotos(photos || []);
    };

    const downloadZip = async (budowa, folderPath, date, id) => {
        if (isDownloading[id]) return; // Jeśli pobieranie już trwa, wyjście z funkcji

        setIsDownloading(prev => ({ ...prev, [id]: true }));
        const zip = new JSZip();
        const folderName = `${budowa}_${date.replaceAll('/', '_')}_${id}`;
        const cleanedFolderPath = folderPath.replace(/\\/g, '/').replace('../beckend/uploads/', '');

        setZipProgress(prev => ({ ...prev, [id]: 1 }));

        try {
            const response = await fetch(`${API_URL}/api/search-photos?folderPath=${encodeURIComponent(cleanedFolderPath)}`);
            if (!response.ok) throw new Error('Nie udało się pobrać zdjęć.');

            const photos = await response.json();

            const filesToFetch = [
                { url: `${API_URL}/uploads/${cleanedFolderPath}/opis.txt`, name: 'opis.txt' },
                { url: `${API_URL}/uploads/${cleanedFolderPath}/data.txt`, name: 'data.txt' },
                { url: `${API_URL}/uploads/${cleanedFolderPath}/bilder.pdf`, name: 'bilder.pdf' },
            ];

            await Promise.all(filesToFetch.map(async (file) => {
                const fileResponse = await fetch(file.url);
                if (fileResponse.ok) {
                    const fileBlob = await fileResponse.blob();
                    zip.file(file.name, fileBlob);
                }
            }));

            const totalItems = photos.length + filesToFetch.length;
            let itemsProcessed = filesToFetch.length;

            await Promise.all(
                photos.map(async (photo) => {
                    const photoResponse = await fetch(`${API_URL}/uploads/${photo.replace(/\\/g, '/')}`);
                    if (!photoResponse.ok) throw new Error('Błąd pobierania zdjęcia: ' + photo);
                    const blob = await photoResponse.blob();
                    const fileName = photo.split('/').pop();
                    zip.file(fileName, blob);

                    itemsProcessed++;
                    const progress = Math.round((itemsProcessed / totalItems) * 100);
                    setZipProgress(prev => ({ ...prev, [id]: progress }));
                })
            );

            const zipBlob = await zip.generateAsync({ type: "blob" });
            saveFileWithProgress(zipBlob, `${folderName}.zip`, id);
        } catch (error) {
            console.error("Błąd podczas tworzenia pliku ZIP:", error);
            setZipProgress(prev => ({ ...prev, [id]: 0 }));
            setDownloadProgress(prev => ({ ...prev, [id]: 0 }));
        } finally {
            setIsDownloading(prev => ({ ...prev, [id]: false })); // Reset stanu po zakończeniu pobierania
        }
    };

    const saveFileWithProgress = (blob, filename, id) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", URL.createObjectURL(blob));
        xhr.responseType = "blob";

        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = Math.round((event.loaded / event.total) * 100);
                setDownloadProgress(prev => ({ ...prev, [id]: progress }));
            }
        };

        xhr.onload = () => {
            const blobUrl = URL.createObjectURL(xhr.response);
            saveAs(blobUrl, filename);
            setDownloadProgress(prev => ({ ...prev, [id]: 0 }));
            setIsDownloading(prev => ({ ...prev, [id]: false })); // Reset po zakończeniu pobierania
        };

        xhr.onerror = () => {
            console.error("Błąd podczas pobierania pliku ZIP");
            setDownloadProgress(prev => ({ ...prev, [id]: 0 }));
            setIsDownloading(prev => ({ ...prev, [id]: false })); // Reset w przypadku błędu
        };

        xhr.send();
    };

    const downloadPdf = async (folderPath) => {
        try {
            const pdfResponse = await fetch(`${API_URL}/uploads/${folderPath}/bilder.pdf`);
            if (!pdfResponse.ok) throw new Error('Brak pliku PDF');
            const pdfBlob = await pdfResponse.blob();
            saveAs(pdfBlob, 'bilder.pdf');
        } catch (error) {
            console.error("Błąd podczas pobierania pliku PDF:", error);
        }
    };

    const openModal = (photoIndex) => {
        setSelectedPhotoIndex(photoIndex);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedPhotoIndex(null);
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <div className="select-container">
                <select
                    value={budowa}
                    onChange={handleBudowaChange}
                    className="select-dropdown"
                >
                    <option value="">Baustelle Auswählen</option>
                    <option value="ahq">AHQ</option>
                    <option value="maxfreii">MaxFreii</option>
                    <option value="centerpark">CenterParcsBostalsee</option>
                </select>
            </div>

            {budowa && (
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <label>
                        Datum<br />
                        <input
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            className="date-picker"
                        />
                    </label>
                    <br />
                    <label>
                        Suchmaschine:<br />
                        <input
                            type="text"
                            placeholder="Beispiel... .7OG, Staubsauger..."
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            className="search-input"
                        />
                    </label>
                </div>
            )}

            <button onClick={handleSearch} className="search-button">Suchen</button>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                {dates.map((dateData, index) => (
                    <div key={index} className="date-entry">
                        <h3 onClick={() => toggleSection(index, dateData.photos)}>
                            Datum<br /> {dateData.date}
                        </h3>
                        {activeIndex === index && (
                            <div>
                                <p>Beschreibung<br /> {dateData.opis}</p>
                                <div className="button-container">
                                    {(zipProgress[dateData.id] > 0 && zipProgress[dateData.id] < 100) && (
                                        <div className="zip-progress">
                                            <div className="spinner"></div>
                                            <div className="spinner-percentage">{zipProgress[dateData.id]}%</div>
                                        </div>
                                    )}
                                    {(zipProgress[dateData.id] === 100 && downloadProgress[dateData.id] > 0) && (
                                        <div className="download-progress">
                                            <div className="spinner"></div>
                                            <div className="spinner-percentage">{downloadProgress[dateData.id]}%</div>
                                        </div>
                                    )}
                                    <button
                                        onClick={() => downloadZip(budowa, dateData.folderPath, dateData.date, dateData.id)}
                                        disabled={isDownloading[dateData.id]}
                                    >
                                        ZIP Download
                                    </button>
                                    <button onClick={() => downloadPdf(dateData.folderPath)}>
                                        PDF Download
                                    </button>
                                </div>
                                <div className="photo-gallery">
                                    {Array.isArray(dateData.photos) && dateData.photos.map((photo, photoIndex) => (
                                        <img
                                            key={photoIndex}
                                            src={`${API_URL}/uploads/${photo}`}
                                            alt={`Zdjęcie ${photoIndex + 1}`}
                                            className="photo-thumbnail"
                                            onClick={() => openModal(photoIndex)}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {isModalOpen && selectedPhotoIndex !== null && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="modal-close" onClick={closeModal}>&times;</span>
                        <button className="modal-arrow left" onClick={() => setSelectedPhotoIndex((selectedPhotoIndex - 1 + modalPhotos.length) % modalPhotos.length)}>&#10094;</button>
                        <img
                            src={`${API_URL}/uploads/${modalPhotos[selectedPhotoIndex]}`}
                            alt="Wybrane zdjęcie"
                            style={{ maxWidth: '100%', maxHeight: '90vh' }}
                        />
                        <button className="modal-arrow right" onClick={() => setSelectedPhotoIndex((selectedPhotoIndex + 1) % modalPhotos.length)}>&#10095;</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchPhotos;
