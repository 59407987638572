import React, { useState, useEffect } from 'react';
import UploadForm from './UploadForm';
import SearchPhotos from './SearchPhotos';
import LoginForm from './LoginForm';
import './App.css';

const App = () => {
    const [activeTab, setActiveTab] = useState('search');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
    };

    if (!isLoggedIn) {
        return <LoginForm onLogin={handleLogin} />;
    }

    return (
        <div className="gradient-background" style={{ width: '100%', height: '100vh' }}>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <h1>Leistungsnachweisen</h1>
                <button onClick={handleLogout} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    Logout
                </button>

                {/* Navigation menu */}
                <div style={{ marginBottom: '20px' }}>
                    <button
                        onClick={() => setActiveTab('search')}
                        style={{
                            padding: '10px 20px',
                            marginRight: '10px',
                            backgroundColor: activeTab === 'search' ? '#007bff' : '#ccc',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        Vorschau
                    </button>

                    <button
                        onClick={() => setActiveTab('upload')}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: activeTab === 'upload' ? '#007bff' : '#ccc',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        Hinzufügen
                    </button>
                </div>

                {/* Display the active tab content */}
                {activeTab === 'search' && <SearchPhotos />}
                {activeTab === 'upload' && <UploadForm />}
            </div>
        </div>
    );
};

export default App;
